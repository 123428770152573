import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.scss";
import { mobileBreakpoint, reportsBreakpoint } from "../../Services/config";
import { useViewport } from "../ViewportProvider";
// State
import { useDispatch, useSelector } from "react-redux";
// Components
import {
  DashboardIcon,
  UsersIcon,
  ReportsIcon,
  StaffIcon,
  InstallIcon,
  FAQIcon,
  CloseIcon,
  SettingsIcon,
} from "../../Styles/Icons/DesignSystem";
import { LilliLogoTealSvg } from "../../Styles/LilliLogoTealSvg";
import theme from "../../Styles/theme.scss";
import { setShowSideDrawer } from "../../State/slices/session-slice";
import BottomSection from "./components/BottomSection";
import { Link } from "react-router-dom";

// Assign icons to the different navigation routes
const NavTypeIcon = {
  DASHBOARD: DashboardIcon,
  LILLI_USERS: UsersIcon,
  STAFF_MEMBERS: StaffIcon,
  INSTALL: InstallIcon,
  REPORTS: ReportsIcon, // looks like a document
  FAQS: FAQIcon,
  Settings: SettingsIcon,
};

// A should highlight utility function to determine whether a link should be highlighted
const shouldHighlight = (item, currentRoute) => {
  // We want the link to be highlighted if we're on that exact page or that we're on a subsequent page eg /staff/id or /lilli-users/id
  return (
    item.link === currentRoute ||
    // Have to exclude '/' as by default it appears in all routes
    (currentRoute.includes(item.link) && item.link !== "/")
  );
};

// A function to return the correct navigation links for any user role
const getNavigationItems = (rolesArray, showReports) => {
  // These are the different navigation options depending on whether the user is logged out, a manager, or a staff member or an installer
  // The following if statements build up the correct nav links for the current user type
  let navigationItems = [];

  // Base navigation for a member of an organisation
  if (
    rolesArray?.includes("admin") ||
    rolesArray?.includes("manager") ||
    rolesArray?.includes("user")
  ) {
    navigationItems.push({
      link: "/",
      name: "Dashboard",
      type: "DASHBOARD",
      ariaLabel: "Dashboard page",
    });
  }

  // Everyone can see the SU list page
  if (
    rolesArray?.includes("admin") ||
    rolesArray?.includes("manager") ||
    rolesArray?.includes("user") ||
    rolesArray?.includes("installer")
  ) {
    navigationItems.push({
      link: "/lilli-users",
      name: "Lilli users",
      type: "LILLI_USERS",
      ariaLabel: "Lilli users page",
    });
  }

  // Everyone except Installers cans see Reports, Staff, and Settings page
  if (
    (rolesArray?.includes("admin") ||
      rolesArray?.includes("manager") ||
      rolesArray?.includes("user")) &&
    showReports
  ) {
    navigationItems.push({
      link: "/reports",
      name: "Reports",
      type: "REPORTS",
      ariaLabel: "Reports page",
    });
    navigationItems.push({
      link: "/staff",
      name: "Staff members",
      type: "STAFF_MEMBERS",
      ariaLabel: "Staff members page",
    });
    navigationItems.push({
      link: "/settings",
      name: "Settings",
      type: "Settings",
      ariaLabel: "Settings page",
    });
  }

  // Only Installers see the install page
  if (rolesArray?.includes("installer")) {
    navigationItems.push({
      link: "/install",
      name: "Install",
      type: "INSTALL",
      ariaLabel: "Installation page",
    });
  }

  // Everyone can see FAQs
  navigationItems.push({
    link: "/faqs",
    name: "FAQs",
    type: "FAQS",
    ariaLabel: "FAQs page",
  });

  return navigationItems;
};

const SideDrawer = ({ navigate }) => {
  // Use react router hook to get the current url
  let location = useLocation();
  const currentRoute = location.pathname;

  // Redux state
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);
  const showSideDrawer = session.showSideDrawer;
  // Tap into the previous state of show side drawer so that we know when to activate the link animation
  const prevShowSideDrawer = useRef();
  // Update the previous state when state changes.
  useEffect(() => {
    prevShowSideDrawer.current = showSideDrawer;
  }, [showSideDrawer]);

  // We only want to animate the buttons where the user has just opened the side drawer and not when they are selecting links within the side drawer
  const shouldAnimate = () =>
    showSideDrawer && prevShowSideDrawer.current === false;

  // Get the width of the viewport for responsive design
  const { width } = useViewport();
  const desktopView = width > mobileBreakpoint;
  const showReports = width > reportsBreakpoint;

  // Get the user from redux state
  const user = useSelector((state) => state.user);

  // Get the navigation items for the side drawer
  const navigationItems = getNavigationItems(user?.roles, showReports);

  // A navigation link component that determines what to render for each navigation item
  const NavLink = ({ item }) => {
    const NavIcon = NavTypeIcon[item.type];

    if (!showSideDrawer) {
      return (
        <Link
          to={item.link}
          className={`${styles.drawerNav} ${
            shouldHighlight(item, currentRoute) ? styles.drawerNav_active : null
          }`}
          aria-label={item.ariaLabel}
        >
          <NavIcon
            width={20}
            colour={
              shouldHighlight(item, currentRoute) ? "#1E2524" : theme.neutral4
            }
          />
        </Link>
      );
    }
    // Otherwise return the link for the side drawer
    return (
      <Link
        to={item.link}
        className={`${styles.drawerNav} ${styles.drawerNav_open} ${
          shouldAnimate() ? styles.drawerNav_animate : null
        } ${
          shouldHighlight(item, currentRoute) ? styles.drawerNav_active : null
        }`}
        aria-label={item.ariaLabel}
      >
        <div className={styles.drawerNav_item}>
          <NavIcon
            width={20}
            colour={
              shouldHighlight(item, currentRoute) ? "#1E2524" : theme.neutral4
            }
          />
          {showSideDrawer && (
            <div
              className={`${styles.drawerNav_item_text} ${
                shouldHighlight(item, currentRoute)
                  ? styles.drawerNav_item_text_active
                  : null
              }`}
            >
              {item.name}
            </div>
          )}
        </div>
      </Link>
    );
  };

  // A closed side drawer. n.b. the css takes care of hiding this on mobile
  if (!showSideDrawer) {
    return (
      <div
        className={styles.hoverArea}
        onMouseOver={() => dispatch(setShowSideDrawer(true))}
      >
        <div className={`${styles.drawer} ${styles.drawer_closed}`}>
          <div>
            <div className={styles.drawer_logo} onClick={() => navigate("/")}>
              {desktopView && <LilliLogoTealSvg />}
            </div>
            {navigationItems?.map((item, index) => (
              <NavLink
                key={index}
                item={item}
                navTypeIcon={NavTypeIcon}
                showSideDrawer={showSideDrawer}
                currentRoute={currentRoute}
                shouldHighlight={shouldHighlight}
                shouldAnimate={shouldAnimate}
              />
            ))}
          </div>
          <div>
            <BottomSection
              showSideDrawer={showSideDrawer}
              user={user}
              shouldHighlight={shouldHighlight}
              shouldAnimate={shouldAnimate}
              currentRoute={currentRoute}
            />
          </div>
        </div>
      </div>
    );
  }
  // Otherwise we render the opened sidedrawer when 'show' is true (web and mobile)
  return (
    showSideDrawer && (
      <div
        className={styles.drawer}
        onMouseLeave={() => dispatch(setShowSideDrawer(false))}
      >
        <div>
          {desktopView ? (
            <div className={styles.drawer_logo} onClick={() => navigate("/")}>
              <LilliLogoTealSvg />
            </div>
          ) : (
            <div
              className={styles.drawer_logo}
              onClick={() => dispatch(setShowSideDrawer(false))}
            >
              <div className={styles.close_icon}>
                <CloseIcon width={16} color={theme.primary6} />
              </div>
            </div>
          )}
          {navigationItems?.map((item, index) => (
            <NavLink
              key={index}
              item={item}
              navTypeIcon={NavTypeIcon}
              showSideDrawer={showSideDrawer}
              currentRoute={currentRoute}
              shouldHighlight={shouldHighlight}
              shouldAnimate={shouldAnimate}
            />
          ))}
        </div>

        <BottomSection
          showSideDrawer={showSideDrawer}
          user={user}
          shouldHighlight={shouldHighlight}
          shouldAnimate={shouldAnimate}
          currentRoute={currentRoute}
        />
      </div>
    )
  );
};

export default SideDrawer;
