import React from "react";
import styles from "../styles.module.scss";
import theme from "../../../Styles/theme.scss";
import { Link } from "react-router-dom";
import { BellIcon } from "../../../Styles/Icons/DesignSystem";
import ProfileIcon from "../../ProfileIcon";

const BottomSection = ({
  showSideDrawer,
  currentRoute,
  user,
  shouldHighlight,
  shouldAnimate,
}) => (
  <div className={styles.drawer_bottomSection}>
    <Link
      to="/notifications"
      className={`${styles.drawerNav} ${
        shouldAnimate() ? styles.drawerNav_animate : null
      } ${
        shouldHighlight({ link: "/notifications" }, currentRoute)
          ? styles.drawerNav_active
          : null
      }`}
      aria-label="Notifications"
    >
      <BellIcon
        width={20}
        colour={
          shouldHighlight({ link: "/notifications" }, currentRoute)
            ? "#1E2524"
            : theme.neutral4
        }
      />
      {showSideDrawer ? (
        <span
          className={`${styles.drawerNav_item_text} ${
            shouldHighlight({ link: "/notifications" }, currentRoute)
              ? styles.drawerNav_item_text_active
              : null
          }`}
        >
          Notifications
        </span>
      ) : null}
    </Link>
    <Link
      to="/profile"
      aria-label="Profile"
      className={`${styles.drawerNav}  ${
        shouldAnimate() ? styles.drawerNav_animate : null
      } ${
        shouldHighlight({ link: "/profile" }, currentRoute)
          ? styles.drawerNav_active
          : null
      }`}
    >
      <ProfileIcon user={user} />
      {showSideDrawer ? (
        <span className={styles.drawerNav_item_text}>Profile</span>
      ) : null}
    </Link>
  </div>
);

export default BottomSection;
