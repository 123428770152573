import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
// State
import { useSelector } from "react-redux";
import { getOrganisation } from "@intelligentlilli/api-layer";
import DashboardJustReleased from "../../Components/DashboardJustReleased";
import { useLocalStorage } from "usehooks-ts";

const DashboardHeader = () => {
  const videoSource =
    "https://www.youtube.com/embed/kGGn4nvcHG4?si=colAKnd1N0T7qLJ6";
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const server = useSelector((state) => state.session.server);
  const [organisation, setOrganisation] = useState();
  const [value, setValue] = useLocalStorage("show-just-released-banner", true);

  useEffect(() => {
    if (user.organisationId) {
      getOrganisation(server, user.organisationId, "web")
        .then((res) => res.ok && setOrganisation(res.body))
        .catch((err) => {
          console.log(err);
          if (err.status === 401) {
            navigate("/login");
          }
        });
    }
  }, [server, user.organisationId, navigate]);

  return (
    <div className={styles.dashboard_header_container}>
      <div>
        <div className={styles.page_title}>Dashboard</div>
        <div className={styles.user_info} data-private>
          {user?.forenames} {user?.surname} | {organisation?.name || ""}
        </div>
      </div>
      {value ? (
        <div className={styles.component_just_released}>
          <DashboardJustReleased
            releaseTitle={"Just Released: PDF downloads for all reports"}
            releaseSubheading={
              "PDFs available for all reports; i.e. night-time, care assessment"
            }
            videoSourceURL={videoSource}
            showButton={false}
            setShowReleaseBanner={setValue}
          />
        </div>
      ) : (
        false
      )}
    </div>
  );
};

export default DashboardHeader;
